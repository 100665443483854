/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    h2: "h2",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Azimut Yachts Unveils Grande 44M: Setting New Standards in Yacht Design and Eco-Friendliness"), "\n", React.createElement(_components.p, null, "Azimut Yachts has recently unveiled their largest fleet flagship model yet, the Grande 44M. This new yacht sets new standards in yacht design and eco-friendliness with its innovative features and reduced CO2 emissions. The Grande 44M is part of Azimut's Low Emission Yachts family and incorporates advanced naval architecture for fuel efficiency and reduced emissions."), "\n", React.createElement(_components.h2, null, "Luxurious Interior Design and Popularity Among Yacht Enthusiasts"), "\n", React.createElement(_components.p, null, "The Grande 44M features a functional and aesthetically pleasing interior design, offering a luxurious onboard lifestyle. Prior to its official launch, the yacht has already sold five units, showcasing its popularity among yacht enthusiasts."), "\n", React.createElement(_components.h2, null, "Where to Find More Information"), "\n", React.createElement(_components.p, null, "Unfortunately, I encountered an error while trying to scrape more information from the website \"", React.createElement(_components.a, {
    href: "https://www.boatinternational.com/yachts/the-superyacht-directory/grande-44m--15550"
  }, "Boat International"), "\". However, I was able to gather some information from the ", React.createElement(_components.a, {
    href: "https://azimutyachts.com/en/grande-series/grande-44m/"
  }, "Azimut Yachts website"), ". The website provides an overview of the Grande 44M, highlighting its technological innovation, design, and sustainability. It also offers options to compare yachts and view pre-owned options."), "\n", React.createElement(_components.p, null, "For more information and a visual preview of the Azimut Yachts Grande 44M, you can watch the official project preview video on YouTube: ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=7u1JXr61x34"
  }, "Azimut Grande 44M | The New Flagship | Project Preview"), "."), "\n", React.createElement(_components.p, null, "Additionally, you can find more details about the Grande 44M on the official Azimut Yachts website: ", React.createElement(_components.a, {
    href: "https://azimutyachts.com/en/grande-series/grande-44m/"
  }, "Azimut Grande 44M"), "."), "\n", React.createElement(_components.p, null, "Please note that the information provided is based on the available sources and may be subject to change."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
